const options = {
  fallbackLng: 'en',
  defaultNS: 'common',
  supportedLngs: [
    'cs',
    'da',
    'de',
    'es',
    'en',
    'fi',
    'fr',
    'it',
    'ja',
    'ko',
    'nb',
    'nl',
    'pl',
    'pt-BR',
    'pt-PT',
    'sv',
    'th',
    'tr',
    'vi',
    'zh-CN',
    'zh-TW',
  ],
};

export default options;
