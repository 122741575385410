import {startTransition, StrictMode} from 'react';
import {RemixBrowser} from '@remix-run/react';
import {hydrateRoot} from 'react-dom/client';
import i18next from 'i18next';
import {I18nextProvider, initReactI18next} from 'react-i18next';
import ShopifyFormat from '@shopify/i18next-shopify';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {getInitialNamespaces} from 'remix-i18next/client';

import i18nextOptions from './i18n/i18nextOptions';

function hydrate() {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <I18nextProvider i18n={i18next}>
          <RemixBrowser />
        </I18nextProvider>
      </StrictMode>,
    );
  });
}

async function initI18n() {
  await i18next
    .use(initReactI18next)
    .use(ShopifyFormat)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      ...i18nextOptions,
      ns: getInitialNamespaces(),
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json`,
        queryStringParams: {
          // eslint-disable-next-line id-length
          v: import.meta.env.VITE_APP_VERSION?.toString().slice(0, 7),
        },
      },
      detection: {
        order: ['htmlTag'],
        caches: [],
      },
    });
}

// eslint-disable-next-line promise/catch-or-return
initI18n().then(hydrate);
